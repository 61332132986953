import React, { Component } from 'react';
import M from 'materialize-css';
import { isMobile } from "react-device-detect";

import Loader from '../../components/Loader';

export default class Select extends Component {
    constructor (props) {
        super(props);

        this.select = React.createRef();
        this.instance = null;
    }

    componentDidMount () {
        this.instance = M.FormSelect.init(this.select.current, {});
    }

    refresh () {
        if (!isMobile && this.instance) {
            this.instance.destroy();
            this.instance = M.FormSelect.init(this.select.current, {});
        }
    }

    render () {
        return (
            <div className={`input-field input-field--select ${ isMobile ? 'is-mobile' : 'is-desktop'  } ${this.props.className ? this.props.className : ''} ${this.props.loading ? 'is-loading' : '' }`}>
                <select
                    className={ isMobile ? 'browser-default' : '' }
                    name={ this.props.name }
                    ref={ this.select }
                    defaultValue={ this.props.defaultValue }
                    value={ this.props.value }
                    onChange={ this.props.onChange }
                    required={ this.props.required }
                    disabled={ this.props.disabled || this.props.loading }
                >
                    { this.props.children }
                </select>

                <label>{ this.props.label }</label>

                {
                    this.props.error &&
                        <span className="helper-text" data-error={ this.props.error }></span>
                }

                {
                    this.props.loading &&
                        <Loader />
                }

                {
                    isMobile &&
                        <svg
                            className="caret"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 10l5 5 5-5z"></path>
                                <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>
                }
            </div>
        );
    }
}
