import React, { Component } from 'react';

export default class Loader extends Component {
    render () {
        return (
            <div className={`c-loader ${this.props.className ? this.props.className : ''}`}>
                <div className="preloader-wrapper center active">
                    <div className="spinner-layer spinner-red-only">
                        <div className="circle-clipper left">
                            <div className="circle"></div>
                        </div>
                        <div className="gap-patch">
                            <div className="circle"></div>
                        </div>
                        <div className="circle-clipper right">
                            <div className="circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
