import React, { Component } from 'react';

import Input from './Input';

export default class InputCollection extends Component {
    constructor (props) {
        super(props);

        this.state = {
            counter: 1
        };
    }

    onAddButtonClick () {
        this.setState({
            counter: this.state.counter + 1
        });
    }

    render () {
        return (
            <React.Fragment>
                {
                    [...Array(this.state.counter).keys()].map(key => {
                        return (
                            <Input
                                key={ key }
                                type="text"
                                name={ this.props.name }
                                label={ this.props.label }
                                required={ this.props.required === 'true' }
                            />
                        )
                    })
                }

                <button className="btn" onClick={ this.onAddButtonClick.bind(this) }>Aggiungi</button>

            </React.Fragment>
        );
    }
}
