import React from "react";
import ReactDOM from "react-dom";

import Chart from './Chart';
import DraftListBox from './DraftListBox';
import IframePreview from './IframePreview';
import InputCollection from './form/InputCollection';

const init = () => {
    const chartComponents = document.querySelectorAll('.js-chartComponent');
    if (chartComponents.length) {
        for (var i = 0; i < chartComponents.length; i++) {
            ReactDOM.render(
                <Chart
                    collection={ chartComponents[i].getAttribute('data-collection') }
                />,
                chartComponents[i]
            );
        }
    }

    const draftListBoxComponent = document.querySelector('.js-draftListBoxComponent');
    if (draftListBoxComponent) {
        ReactDOM.render(
            <DraftListBox />,
            draftListBoxComponent
        );
    }

    const iframePreviewComponent = document.querySelector('.js-iframePreview');
    if (iframePreviewComponent) {
        const children = iframePreviewComponent.querySelectorAll('div');
        const options = [];

        for (var i = 0; i < children.length; i++) {
            options.push({
                value: children[i].getAttribute('data-url'),
                text: children[i].innerHTML
            });
        }

        ReactDOM.render(
            <IframePreview
                options={ options }
            />,
            iframePreviewComponent
        );
    }

    const inputCollectionComponents = document.querySelectorAll('.js-inputCollection');
    if (inputCollectionComponents.length) {
        for (var i = 0; i < inputCollectionComponents.length; i++) {
            ReactDOM.render(
                <InputCollection
                    name={ inputCollectionComponents[i].getAttribute('data-name') }
                    label={ inputCollectionComponents[i].getAttribute('data-label') }
                    required={ inputCollectionComponents[i].getAttribute('data-required') }
                />,
                inputCollectionComponents[i]
            );

        }
    }
}

export default { init }
