import React, { Component } from 'react';

export default class Input extends Component {
    constructor (props) {
        super(props);

        this.types = [
            'text',
            'textarea',
            'number',
            'email',
            'password'
        ];
    }

    render () {
        if (this.types.indexOf(this.props.type) == -1) {
            throw `Type "${this.props.type}" is not accepted`;
            return false;
        }

        return (
            <div className={ `input-field ${this.props.className ? this.props.className : ''}` }>
                {
                    this.props.icon &&
                        <i className="material-icons postfix">{ this.props.icon }</i>
                }

                {
                    this.props.type == 'textarea' &&
                        <textarea
                            className="materialize-textarea"
                            id={ this.props.name }
                            name={ this.props.name }
                            defaultValue={ this.props.value || '' }
                            required={ this.props.required }
                            onChange={ this.props.onChange }
                            onInput={ this.props.onInput }
                            onKeyPress={ this.props.onKeyPress }
                            onKeyDown={ this.props.onKeyDown }
                            onKeyUp={ this.props.onKeyUp }
                            autoFocus={ this.props.autofocus }
                            autoComplete={ this.props.autocomplete }
                            disabled={this.props.disabled}
                            maxLength={this.props.maxLength}
                            pattern={this.props.pattern}
                            style={{ minHeight:120 }}
                        ></textarea>
                }

                {
                    this.props.type != 'textarea' &&
                        <input
                            type={ this.props.type }
                            id={ this.props.name }
                            name={ this.props.name }
                            defaultValue={ this.props.value }
                            required={ this.props.required }
                            onChange={ this.props.onChange }
                            onInput={ this.props.onInput }
                            onKeyPress={ this.props.onKeyPress }
                            onKeyDown={ this.props.onKeyDown }
                            onKeyUp={ this.props.onKeyUp }
                            step={ this.props.step }
                            autoFocus={ this.props.autofocus }
                            autoComplete={ this.props.autocomplete }
                            disabled={this.props.disabled}
                            maxLength={this.props.maxLength}
                            pattern={this.props.pattern}
                        />

                }

                <label htmlFor={ this.props.name } className={this.props.value && this.props.value.length > 0 ? 'active' : ''}>{ this.props.label }</label>

                {
                    this.props.error &&
                        <span className="helper-text" data-error={ this.props.error }></span>
                }

                {
                    this.props.helper &&
                        <span className="helper-text">{ this.props.helper }</span>
                }
            </div>
        );
    }
}
