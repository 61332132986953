import React, { Component } from 'react';

import t from '../../lang';
import Select from './form/Select';

export default class IframePreview extends Component {
    constructor (props) {
        super(props);

        this.state = {
            url: null
        };
    }

    onChange (e) {
        this.setState({
            url: e.target.value
        });
    }

    componentDidMount () {
        this.setState({
            url: this.props.options[0].value
        });
    }

    render () {
        return (
            <div className="c-iframePreview">
                <Select
                    className="c-iframePreview__select"
                    label={ t('Select preview') }
                    onChange={ this.onChange.bind(this) }
                >
                    {
                        this.props.options &&
                            this.props.options.map((item, index) => {
                                return (<option value={ item.value } key={ index }>{ item.text }</option>)
                            })
                    }
                </Select>
                <div className="c-iframePreview__box">
                    <iframe src={ this.state.url }></iframe>
                </div>
            </div>
        );
    }
}
