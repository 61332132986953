import React, {Component} from 'react';
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis} from 'recharts';

import {transMonthsShort} from '../../lang';

export default class Chart extends Component {

    constructor (props) {
        super(props);
        this.collection = JSON.parse(this.props.collection).map(item => {
            return {
                ...item,
                name: transMonthsShort(item.month)
            }
        })
    }

	render () {
      	return (
            <React.Fragment>
                {
                    this.props.collection &&
                    	<ResponsiveContainer
                            aspect={16/9}>
                        	<BarChart
                                data={ this.collection }
                                margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <XAxis dataKey="name"/>
                                    <YAxis/>
                                    <Bar dataKey="value" barSize={40} fill="#ff3e17" />
                            </BarChart>
                        </ResponsiveContainer>
                }
            </React.Fragment>
        );
    }
}
