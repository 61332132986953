import React, { Component } from 'react';

import t from '../../../lang';

export default class File extends Component {
    constructor (props) {
        super(props);

        this.fileInput = React.createRef()
    }

    onResetClick (e) {
        e.preventDefault();
        this.fileInput.current.value = '';

        if (typeof this.props.onReset === 'function') {
            this.props.onReset(e);
        }
    }

    render () {
        return (
            <React.Fragment>
                <div className={ `input-field input-field--file file-field ${this.props.className}` }>
                    <label htmlFor="files" className="active">{ this.props.label }</label>
                    <div className="btn">
                        <span>
                            <i className="material-icons">cloud_upload</i>
                        </span>
                        <input
                            ref={ this.fileInput }
                            accept={ this.props.accept || '*' }
                            multiple={ this.props.multiple }
                            id={ this.props.name }
                            name={ `${this.props.name}${this.props.multiple ? '[]' : ''}` }
                            disabled={this.props.disabled}
                            type="file"
                            onChange={ this.props.onChange }
                        />
                    </div>

                    {
                        this.props.placeholder &&
                            <div className="file-path-wrapper">
                                <input type="text" placeholder={ this.props.placeholder } />
                            </div>
                    }
                </div>

                {
                    this.props.reset &&
                        <div className="input-field--file__reset">
                            <a href="#!" onClick={ this.onResetClick.bind(this) }>{ t('Reset') }</a>
                        </div>
                }
            </React.Fragment>
        );
    }
}
