import React, { Component } from 'react';

export default class BlankBox extends Component {
    constructor (props) {
        super(props);
    }

    render () {
        return (
            <div className="c-blank-box">
                {
                    this.props.showText &&
                        <span>{ this.props.text }</span>
                }
            </div>
        );
    }
}
