import React, { Component } from 'react';

import t from '../../lang';

import BlankBox from './BlankBox';
import File from './form/File';
import Loader from './Loader';

export default class DraftListBox extends Component {
    constructor (props) {
        super(props);

        this.state = {
            selectedFiles: [],
            showAllExistFiles: this.props.showAllExistFiles
        };

        this.fileInput = React.createRef();
        this.limit = this.props.limit || 3;
    }

    onChange (e) {
        const list = [];
        const formData = new FormData();

        for (var i = 0; i < e.target.files.length; i++) {
            const file = e.target.files[i];

            if (this.props.onUpdate) {
                formData.append(`file[${i}]`, file);
            }

            list.push(file);
        }

        this.setState({
            selectedFiles: list
        });

        if (this.props.onUpdate) {
            this.props.onUpdate(formData);
        }
    }

    onReset (e) {
        this.setState({
            selectedFiles: []
        });
    }

    onShowButtonClick (e) {
        e.preventDefault();

        this.setState({
            showAllExistFiles: !this.state.showAllExistFiles
        });
    }

    onRemoveButtonClick (file) {
        if (this.props.onRemoveFile) {
            this.props.onRemoveFile(file);
        }
    }

    renderSelectedFile (file) {
        return (
            <div className="c-draft" key={ file.name }>
                <span className="c-draft__link">
                    <i className="material-icons">insert_drive_file</i> { file.name }
                </span>
            </div>
        );
    }

    renderExistingFile (file) {
        return (
            <div className="c-draft" key={ file.name }>
                <span className="c-draft__link">
                    <i className="material-icons">insert_drive_file</i> { file.name }
                </span>
                <div className="c-draft__cta">
                    <a href={ file.url } download className="c-draft__btn btn-floating waves-effect waves-light grey mr-1">
                        <i className="material-icons">cloud_download</i>
                    </a>

                    {
                        !this.props.readOnly &&
                            <button onClick={ (e) => { this.onRemoveButtonClick(file) }} className="c-draft__btn btn-floating waves-effect waves-light">
                                <i className="material-icons">remove</i>
                            </button>
                    }
                </div>
            </div>
        )
    }

    componentDidUpdate (prevProps, prevState) {
        if (prevProps.existFiles && this.state.selectedFiles.length > 0) {
            this.setState({
                selectedFiles: []
            });
        }
    }

    render () {
        return (
            <div className="c-draftsList">
                {
                    !this.props.readOnly &&
                        <File
                            className="mb-0"
                            multiple
                            name="attachments"
                            reset={ !this.props.directUpload && this.state.selectedFiles.length > 0 }
                            onReset={ this.onReset.bind(this) }
                            onChange={ this.onChange.bind(this) }
                            label={ t('Upload your docs') }
                            placeholder={ t('Choose one or more file') }
                        />
                }

                {
                    this.state.selectedFiles.map((item) => {
                        return this.renderSelectedFile(item)
                    })
                }

                {
                    (this.props.existFiles && this.state.showAllExistFiles) &&
                        this.props.existFiles.map((item) => {
                            return this.renderExistingFile(item)
                        })
                }

                {
                    (this.props.existFiles && !this.state.showAllExistFiles) &&
                        this.props.existFiles.slice(0, this.limit).map((item) => {
                            return this.renderExistingFile(item)
                        })
                }

                {
                    (this.props.existFiles && this.props.existFiles.length > this.limit) &&
                        <div className="c-draftsList__show">
                            <button className="btn-flat small" onClick={ this.onShowButtonClick.bind(this) }>{ t(this.state.showAllExistFiles ? 'Hide' : `${t('Show All')} (${this.props.existFiles.length - this.limit})`) }</button>
                        </div>
                }

                {
                    ((this.props.existFiles && this.props.existFiles.length == 0) || (!this.props.existFiles && this.state.selectedFiles.length == 0)) &&
                        <BlankBox text={ t('No Files uploaded') } showText />
                }
            </div>
        );
    }
}
